<template>
  <div class="">
    <v-layout row wrap justify-space-around class="mt-7">
      <v-flex class="xs12 sm6 md6 lg6 white elevation-2 rounded">
        <v-layout row wrap justify-end>
          <v-spacer></v-spacer>
          <v-flex xs7 sm5 md5 lg5 class="mt-3 pr-3 text-right">
            <v-menu offset-y v-model="menuLang" class="">
              <template v-slot:activator="{ on }">
                <v-btn text v-on="on" style="cursor: pointer">
                  <flag :iso="$i18n.locale == 'en' ? 'us' : $i18n.locale" />
                  <span class="ml-2 subtitle-2">{{
                    $i18n.locale.toUpperCase()
                  }}</span>
                </v-btn>
              </template>
              <v-list dense max-height="300" class="overflow-y-auto pa-0" tile>
                <div v-for="item in languages" :key="item.lang">
                  <v-list-item @click="switchLanguage(item.lang)" class="">
                    <v-list-item-icon>
                      <flag :iso="item.flag" />
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title class="caption">
                        {{ item.lang.toUpperCase() }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider></v-divider>
                </div>
              </v-list>
            </v-menu>
          </v-flex>
        </v-layout>
        <v-layout row wrap class="my-4 px-2">
          <v-flex xs12 sm12 md12 lg12 class="text-center">
            <p class="font-weight-bold text-h6 text-uppercase">
              {{ title }}
            </p>
          </v-flex>
          <v-spacer></v-spacer>
        </v-layout>
        <v-form ref="form" class="">
          <span class="px-4 font-weight-medium text-capitalize body-2"
            >{{ $t("username") }}
          </span>
          <v-text-field
            outlined
            dense
            v-model="post.username"
            @keyup.enter="validate"
            :rules="rules.username"
            maxlength="20"
            class="px-4"
          ></v-text-field>

          <span class="px-4 font-weight-medium text-capitalize body-2"
            >{{ $t("password") }}
          </span>
          <v-text-field
            outlined
            dense
            v-model="post.password"
            @keyup.enter="validate"
            @click:append="viewPass = !viewPass"
            :append-icon="viewPass ? 'mdi-eye-off' : 'mdi-eye'"
            :type="viewPass ? 'text' : 'password'"
            :rules="rules.password"
            class="px-4"
          ></v-text-field>
        </v-form>
        <!-- fogort password row -->
        <v-layout row wrap class="px-7">
          <v-spacer></v-spacer>
          <v-flex xs12 sm6 md6 lg6 class="text-right">
            <p
              class="blue--text mt-4 caption"
              @click="forgotPassword()"
              style="cursor: pointer; text-decoration: underline"
            >
              {{ $t("forgot password") }}
            </p>
          </v-flex>
        </v-layout>
        <v-layout row wrap class="mb-2 px-7">
          <v-flex xs12 sm12 md12 lg12>
            <v-btn
              :color="$store.state.secondaryColor"
              dark
              block
              @click="validate()"
              :loading="loading"
            >
              {{ $t("sign in") }}
            </v-btn>
          </v-flex>
        </v-layout>
        <v-layout row wrap class="px-7">
          <v-spacer></v-spacer>
          <v-flex xs12 sm12 md12 lg12 class="text-right">
            <p class="caption">
              {{ $t("don't have an account?") }}
              <span
                class="blue--text mt-4"
                @click="selfRegister()"
                style="cursor: pointer; text-decoration: underline"
              >
                {{ $t("register here") }}</span
              >
            </p>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>

    <v-snackbar
      v-model="snackError"
      top
      color="red lighten-5 red--text"
      :timeout="4000"
    >
      {{ $t("E-mail or password not valid") }}
      <template v-slot:action="{ attrs }">
        <v-btn icon v-bind="attrs" color="red" @click="snackError = false">
          <v-icon>close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <v-snackbar
      v-model="snackErrorLicense"
      top
      color="red lighten-5 red--text"
      :timeout="4000"
    >
      {{
        $t("your License has expired. Please contact our support desk for help")
      }}

      <template v-slot:action="{ attrs }">
        <v-btn
          icon
          v-bind="attrs"
          color="red"
          @click="snackErrorLicense = false"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <v-snackbar
      v-model="snackDenied"
      top
      color="red lighten-5 red--text"
      :timeout="4000"
    >
      <span>{{ $t("access denied") }}</span>
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" icon color="red" @click="snackDenied = false">
          <v-icon>close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <v-snackbar
      v-model="snackSuccess"
      top
      color="green lighten-4 green--text text--darken-3"
      :timeout="4000"
    >
      <span>{{ $t("access granted") }}</span>

      <template v-slot:action="{ attrs }">
        <v-btn
          v-bind="attrs"
          icon
          color="green darken-3"
          @click="snackSuccess = false"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <v-snackbar
      v-model="snackOnline"
      top
      color="warning lighten-4 warning--text text--darken-3"
      :timeout="4000"
    >
      <span>{{ $t("offline-message") }}</span>

      <template v-slot:action="{ attrs }">
        <v-btn
          v-bind="attrs"
          icon
          color="warning darken-3"
          @click="snackOnline = false"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <v-dialog v-model="dialogAlreadyLoggedIn" max-width="440">
      <v-card dark>
        <v-card-text class="pa-4">
          <span>
            This user is already Logged !!! <br />
            During Production you will be advised to either Log the other brower
            and continue on the current user or <br />
            use Keep other Browser Open</span
          >
        </v-card-text>

        <v-card-actions class="mx-4">
          <v-btn
            text
            :color="$store.state.secondaryColor"
            @click="dialogAlreadyLoggedIn = false"
          >
            CANCEL
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            text
            :color="$store.state.secondaryColor"
            @click="continueToDashboard(userData)"
          >
            USE HERE
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <Footer />
  </div>
</template>

<script>
import Footer from "@/components/layout/loginPageFooter";
// import axios from '@/plugins/axios'
import db from "@/plugins/fb";
import jwt from "jsonwebtoken";
import jwtdecode from "jwt-decode";

import { differenceInDays, format } from "date-fns";

export default {
  components: {
    Footer,
  },
  data() {
    return {
      menuLang: false,
      languages: [
        {
          flag: "us",
          lang: "en",
        },
        {
          flag: "fr",
          lang: "fr",
        },
      ],
      snackError: false,
      snackSuccess: false,
      snackLogout: false,
      snackOnline: false,
      snackDenied: false,
      checkbox: true,
      snackErrorLicense: false,
      loading: false,
      userData: "",
      licenceStatus: "",
      paymentStatus: "",
      freeTrialExpiryDate: "",
      post: {
        username: "",
        password: "",
      },
      viewPass: false,
      response: null,
      isSelected: false,
      dialogAlreadyLoggedIn: false,
      businesses: [],
      businessId: "",
      ownerBusiness: [],
      countries: ["English", "French"],
    };
  },
  computed: {
    title() {
      return this.$t("sign in");
    },
    rules() {
      return {
        username: [
          (v) => !!v || this.$t("username is required"),
          (v) =>
            (v && v.length >= 4) ||
            this.$t("username must not be less than 4 characters"),
        ],
        password: [(v) => !!v || this.$t("password is required")],
        emptyField: [(v) => !!v || this.$t("this field is required")],
      };
    },
  },

  mounted() {
    // localStorage.clear();
    // let cleardata = {
    //   status: false,
    //   userId: "",
    // };

    // this.$store.commit("SET_SESSION", cleardata);
    window.addEventListener("online", this.checkConnection);
    window.addEventListener("offline", this.checkConnection);
  },

  methods: {
    checkConnection() {
      this.snackOnline = navigator.onLine ? false : true;
    },

    validate() {
      if (this.$refs.form.validate()) {
        // if (navigator.onLine) return this.authenticate();
        // else return this.checkConnection();
        this.authenticate();
      }
    },

    authenticate() {
      this.loading = true;
      db.collection("users")
        .where("username", "==", this.post.username)
        .get()
        .then((snapshot) => {
          this.userData = null;

          if (snapshot.size > 0) {
            snapshot.forEach((snaps) => {
              if (
                snaps.data().accessRight &&
                snaps.data().password == this.post.password
              ) {
                if (snaps.data().status) {
                  this.userData = snaps.data();
                  this.userData.id = snaps.id;

                  this.loading = false;
                  this.dialogAlreadyLoggedIn = true;
                } else {
                  this.userData = snaps.data();
                  this.userData.id = snaps.id;
                  //separate the functions for each type
                  if (this.userData.isSuperAdmin) {
                    this.loginAccessAdmin();
                  } else if (this.userData.isOwner) {
                    this.checkLicense();
                  } else {
                    this.loginAccessEmployee();
                  }

                  this.loading = false;
                }
              } else {
                if (!snaps.data().accessRight) {
                  this.snackDenied = true;
                  this.loading = false;
                } else this.accessDenied();
              }
            });
          } else {
            this.accessDenied();
          }
        });
    },

    loginAccessAdmin() {
      let sessionData = {
        status: true,
        userId: this.userData.username,
      };
      this.$store.commit("SET_SESSION", sessionData);
      this.snackSuccess = true;

      var userdetails = "";
      var token;
      const key = this.$store.state.accessKey;

      db.collection("rootAccess")
        .where("email", "==", this.userData.username)
        .get()
        .then((snapshot) => {
          snapshot.forEach((snaps) => {
            userdetails = snaps.data();
            userdetails.accessLevel = this.userData;
            token = jwt.sign(userdetails, key, { expiresIn: "1d" });

            this.$store.commit("SET_TOKEN", token);
            this.$router.push({ name: "Dashboard" });
          });
        });

      this.setLoginStatus();
      this.setCurrency();
    },

    //determine the payment status. If true, proceed.
    checkLicense() {
      this.loading = true;
      //########################################
      db.collection("licenses")
        .where("ownerId", "==", this.post.username)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.licenceStatus = doc.data().isValid;

            if (doc.data().freeTrialExpiryDate) {
              this.freeTrialExpiryDate = doc.data().freeTrialExpiryDate;
              //get number of days left in trial
              let today = format(new Date(), "yyyy,MM,dd");

              let futureDate = format(
                this.freeTrialExpiryDate.toDate(),
                "yyyy,MM,dd"
              );
              //calculate days remaining in license
              this.daysLeftFreeTrial = differenceInDays(
                new Date(futureDate),
                new Date(today)
              );
            }
          });
          if (this.daysLeftFreeTrial != "" && this.daysLeftFreeTrial >= 14) {
            //allow user to sign in under free trial
            this.loginAccessOwner();
          } else {
            //check if payment status is true
            if (this.licenceStatus) {
              //allow user to sign in if true
              this.loginAccessOwner();
            } else {
              this.snackErrorLicense = true;
              this.loading = false;
              this.post.password = null;
            }
          }
        });

      //########################################
    },
    //#################

    //#################
    loginAccessOwner() {
      let sessionData = {
        status: true,
        userId: this.userData.username,
      };
      this.$store.commit("SET_SESSION", sessionData);
      this.snackSuccess = true;

      var userdetails = "";
      var token;
      const key = this.$store.state.accessKey;

      db.collection("owners")
        .doc(this.userData.username)
        .get()
        .then((snaps) => {
          userdetails = snaps.data();
          userdetails.accessLevel = this.userData;
          token = jwt.sign(userdetails, key, { expiresIn: "1d" });

          this.$store.commit("SET_TOKEN", token);

          this.$router.push({ name: "SelectBusiness" });
        });

      this.setLoginStatus();
      this.setCurrency();
    },

    loginAccessEmployee() {
      db.collection("employees")
        .where("employeeId", "==", this.userData.username)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.businessId = doc.data().businessId;
          });
          this.loading = true;
          //get the business ID
          this.getBusinessOwner();
        });
    },
    //use business ID to find employer
    getBusinessOwner() {
      db.collection("businesses")
        .doc(this.businessId)
        .get()
        .then((doc) => {
          this.ownerBusiness.push({
            id: doc.id,
            ...doc.data(),
          });

          this.loading = true;

          this.$store.commit("SET_BUSINESS", this.ownerBusiness);

          //check license validity of owner
          this.checkLicenseEmployee();
          this.storeEmployerId();
        });
    },

    storeEmployerId() {
      //store emplyer ID for future referencing
      this.$store.commit("SET_EMPLOYERID", this.ownerBusiness[0].ownerId);
    },

    //determine the payment status. If true, proceed.
    checkLicenseEmployee() {
      this.loading = true;
      //########################################
      db.collection("licenses")
        .where("ownerId", "==", this.ownerBusiness[0].ownerId)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.licenceStatus = doc.data().isValid;
            if (doc.data().freeTrialExpiryDate) {
              this.freeTrialExpiryDate = doc.data().freeTrialExpiryDate;
              //get number of days left in trial
              let today = format(new Date(), "yyyy,MM,dd");

              let futureDate = format(
                this.freeTrialExpiryDate.toDate(),
                "yyyy,MM,dd"
              );
              //calculate days remaining in license
              this.daysLeftFreeTrial = differenceInDays(
                new Date(futureDate),
                new Date(today)
              );
            }
          });
          if (this.daysLeftFreeTrial != "" && this.daysLeftFreeTrial >= 14) {
            //allow user to sign in under free trial
            this.employeeLogin();
          } else {
            //check if payment status is true
            if (this.licenceStatus) {
              ////this.paymentStatus = this.licenceStatus;
              //allow user to sign in if true
              this.employeeLogin();
            } else {
              this.snackErrorLicense = true;
              this.loading = false;
              this.post.password = null;
            }
          }
        });

      //########################################
    },

    employeeLogin() {
      let sessionData = {
        status: true,
        userId: this.userData.username,
      };
      this.$store.commit("SET_SESSION", sessionData);
      this.snackSuccess = true;

      var userdetails = "";
      var token;
      const key = this.$store.state.accessKey;

      db.collection("employees")
        .doc(this.userData.username)
        .get()
        .then((snaps) => {
          userdetails = snaps.data();
          userdetails.accessLevel = this.userData;
          token = jwt.sign(userdetails, key, { expiresIn: "1d" });
          this.$store.commit("SET_TOKEN", token);

          this.$store.commit("SET_BUSINESS_ID", snaps.data().businessId);
          //set employee's business to store
          this.$store.commit("SET_SELECTED_BUSINESS", snaps.data().businessId);
          //create a login attendance
          this.createAttendance();
        });

      this.setLoginStatus();
      this.setCurrency();
    },
    //create attendance and route users to appropriate locations
    createAttendance() {
      var attendance = {
        employeeId: this.userData.username,
        dateTimeIn: new Date(),
        dateTimeOut: new Date(),
      };
      if (this.$refs.form.validate()) {
        this.loading = true;
        db.collection("attendances")
          .add(attendance)
          .then(() => {
            //Fetching current user details
            var data = jwtdecode(
              this.$store.state.token,
              this.$store.state.accessKey
            );
            var userAccess = data.accessLevel.isSuperAdmin
              ? 0
              : data.accessLevel.isOwner
              ? 1
              : data.accessLevel.isManager
              ? 2
              : data.accessLevel.isWaiter
              ? 3
              : data.accessLevel.isChef
              ? 4
              : data.accessLevel.isCashier
              ? 5
              : 6;
            //redirect
            if (userAccess == 1) {
              this.$router.push("/");
            } else if (userAccess == 2) {
              this.$store.commit("SET_WINDOWTITLE", "Manager");
            } else if (userAccess == 3) {
              this.$store.commit("SET_WINDOWTITLE", "Waiter");
              this.$router.push({ name: "RestaurantPOS" });
            } else if (userAccess == 4) {
              this.$store.commit("SET_WINDOWTITLE", "Chef");
              this.$router.push({ name: "Orders" });
            } else if (userAccess == 5) {
              this.$store.commit("SET_WINDOWTITLE", "Cashier");
              this.$router.push({ name: "Orders" });
            }
          });
      }
    },
    accessDenied() {
      this.snackError = true;
      this.loading = false;
      this.post.password = null;
    },

    setCurrency() {
      //if user is an employee, use currency set by owner of the business
      if (this.userData.isEmployee) {
        db.collection("configurations")
          .doc(this.ownerBusiness[0].ownerId)
          .get()
          .then((snaps) => {
            if (snaps.exists) {
              this.storeCurrency(snaps.data().currency);
            } else {
              //DEFAULT CURRENCY : USD
              db.collection("currencies")
                .where("label", "==", "USD")
                .get()
                .then((snapshot) => {
                  snapshot.forEach((snaps) => {
                    db.collection("configurations")
                      .doc(this.ownerBusiness[0].ownerId)
                      .set({
                        currency: snaps.id,
                        timezone: "",
                        paymentMode: "",
                      })
                      .then(() => {
                        this.storeCurrency(snaps.id);
                      });
                  });
                });
            }
          });
      } else {
        db.collection("configurations")
          .doc(this.userData.username)
          .get()
          .then((snaps) => {
            if (snaps.exists) {
              this.storeCurrency(snaps.data().currency);
            } else {
              //DEFAULT CURRENCY : USD
              db.collection("currencies")
                .where("label", "==", "USD")
                .get()
                .then((snapshot) => {
                  snapshot.forEach((snaps) => {
                    db.collection("configurations")
                      .doc(this.userData.username)
                      .set({
                        currency: snaps.id,
                        timezone: "",
                        paymentMode: "",
                      })
                      .then(() => {
                        this.storeCurrency(snaps.id);
                      });
                  });
                });
            }
          });
      }
    },

    storeCurrency(currency) {
      let currencydata;
      db.collection("currencies")
        .doc(currency)
        .get()
        .then((doc) => {
          currencydata = {
            owner: this.userData.username,
            id: doc.id,
            ...doc.data(),
          };
          this.$store.commit("SET_CURRENCY", currencydata);
        });
    },

    setLoginStatus() {
      db.collection("users").doc(this.userData.id).update({
        status: true,
      });

      var logsData = {
        dateTimeIn: new Date(),
        dateTimeOut: null,
        username: this.userData.id,
      };

      //Add Logs to database and to store
      db.collection("logs")
        .add(logsData)
        .then((snaps) => {
          // let logs = {
          //   id: snaps.id,
          //   username:id
          // }

          let logs = snaps.id;
          this.$store.commit("SET_LOGS", logs);
        });
    },

    switchLanguage(lang) {
      this.$i18n.locale = lang;
      //store translation language to store
      localStorage.setItem("lang", lang);
      this.$router.push({ params: { lang: lang } });
      //setTimeout(() => window.location.reload(), 500);
    },
    forgotPassword() {
      this.$router.push({ name: "ForgotPassword" });
    },
    selfRegister() {
      this.$router.push({ name: "Register" });
    },
    continueToDashboard(data) {
      if (data.isSuperAdmin) {
        this.loginAccessAdmin(data);
      } else if (data.isOwner) {
        this.checkLicense();
      } else if (data.isEmployee) {
        this.loginAccessEmployee(data);
      }
    },
  },
};
</script>

<style scoped>
.full-height {
  height: 100%;
  background-image: linear-gradient(to bottom, #f0af7a, #62d2fa);
}
.img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30%;
  margin-bottom: 30%;
}
</style>
